<template>
  <section>
    <app-card-title @filter="filter" />
    <app-expense-card @addNewExpense="addNewExpense" :expense="summary" />
    <v-col cols="12" class="px-0">
      <data-table
        :headers="headers"
        :items="expense"
        commit="SET_EXCHANGE"
        :viewItem="viewItem"
        :editItem="editItem"
        :deleteItem="deleteItem"
      >
        <div slot="activator" class="row justify-content-between">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <v-card-title class="px-0 pt-0">{{ $t("expense") }} </v-card-title>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <TextSearch
              v-model="search"
              @search="fetchItem()"
              @onClear="
                search = '';
                fetchItem();
              "
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-end">
            <v-btn depressed color="primary" @click="newItem">
              <v-icon left>fal fa-plus-circle</v-icon>
              {{ $t("new_data") }}
            </v-btn>
          </div>
        </div>
      </data-table>
      <app-custom-dialog
        v-model="dialog"
        :footer="false"
        title="description"
        @submit="submitForm"
      >
        <div slot="activator">
          <v-row>
            <v-col cols="4">{{ $t("id") }}</v-col>
            <v-col cols="8">{{ form.id }}</v-col>
            <v-col cols="4">{{ $t("name") }} </v-col>
            <v-col cols="8">{{ form.name }}</v-col>
            <v-col cols="4">{{ $t("category") }} </v-col>
            <v-col cols="8">{{ form.category.name }}</v-col>
            <v-col cols="4">{{ $t("type") }}</v-col>
            <v-col cols="8">
              <v-chip
                label
                :color="form.type === 'income' ? 'green' : 'red'"
                small
                class="white--text"
              >
                {{ $t(form.type) }}</v-chip
              >
            </v-col>
            <v-col v-if="form.price && dialog" cols="4">{{
              $t("price")
            }}</v-col>
            <v-col v-if="form.price && dialog" cols="8">{{
              moneyFormat(form.price)
            }}</v-col>
            <v-col cols="4">{{ $t("created_at") }}</v-col>
            <v-col cols="8">{{ form.created_at | moment }}</v-col>
          </v-row>
        </div>
      </app-custom-dialog>
      <app-custom-dialog
        v-model="dialogAdd"
        :footer="true"
        :title="titleStatus"
        @submit="submitForm"
      >
        <div slot="activator">
          <TTextField
            label="name"
            :errorMessages="nameError"
            v-model="form.name"
            @input="$v.form.name.$touch()"
            @blur="$v.form.name.$touch()"
          />
          <VuetifyMoney
            :options="options"
            label="price"
            :errorMessages="priceError"
            typeShow="number"
            v-model.number="form.price"
            @input="$v.form.price.$touch()"
            @blur="$v.form.price.$touch()"
          />
          <TextSelect
            :errorMessages="category_idError"
            label="category"
            v-model="form.category_id"
            @input="$v.form.category_id.$touch()"
            @blur="$v.form.category_id.$touch()"
            itemValue="id"
            text="name"
            :items="category"
          />
          <TextSelect
            :errorMessages="typeError"
            label="type"
            itemValue="value"
            text="value"
            v-model="form.type"
            @input="$v.form.type.$touch()"
            @blur="$v.form.type.$touch()"
            :items="items"
          />
        </div>
      </app-custom-dialog>
    </v-col>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { expenseHead } from "@/utils/head";
import AppExpenseCard from "@/components/expense/card";
import AppCardTitle from "@/components/expense/card-title";
import TTextField from "@/components/global/TextField";
import TextSelect from "@/components/global/TextSelect";
import { myMixin } from "@/mixin/main";
import { expenseValidate } from "@/validations/shop";
import { success, confirm } from "@/controllers/alert";
import VuetifyMoney from "@/components/VuetifyMoney";

export default {
  mixins: [myMixin, expenseValidate],
  data: () => ({
    options: {
      precision: 0,
    },
    update: false,
    dialogAdd: false,
    dialog: false,
    overlay: false,
    search: "",
    summary: [],
    items: [
      {
        name: "ລາຍຮັບ",
        value: "income",
      },
      {
        name: "ລາຍຈ່າຍ",
        value: "expense",
      },
    ],
    form: {
      category_id: "",
      category: {},
    },
  }),
  async created() {
    await this.fetchItem();
    await this.getSummary();
  },
  components: {
    DataTable,
    VuetifyMoney,
    TextSelect,
    TTextField,
    AppExpenseCard,
    AppCardTitle,
  },
  computed: {
    ...mapGetters({
      expense: "index/expense",
      category: "index/category",
    }),
    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return expenseHead(this);
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem(val) {
      const body = {
        that: this,
        url: `expense_paginate?q=${this.search}`,
        commit: "SET_EXPENSE",
      };
      await this.fetch(body);
    },
    async fetchCategory() {
      const body = {
        that: this,
        url: "expense_category",
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      await this.fetchCategory();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`expense/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async addNewExpense(val) {
      await Help.clearForm(this.form, this);
      this.form.type = val;
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async submitForm() {
      var result;
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.form.category_id = this.form.category_id.id;
          this.form.type = this.form.type.value;
          if (this.update) {
            result = await this.$http.put(`expense/${this.form.id}`, this.form);
          } else {
            result = await this.$http.post("expense", this.form);
          }
          if (result.data.status) {
            this.dialogAdd = !this.dialogAdd;
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await this.getSummary();
            await success(this, result.data.message);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async filter(val) {
      this.overlay = true;
      await this.getSummary(val);
      this.overlay = false;
    },

    async getSummary(val) {
      let url = `expense_summary`;
      if (val) {
        url = `expense_summary?q=${val}`;
      }
      const { data } = await this.$http.get(`${url}`);
      if (data.status) {
        this.summary = data.data;
      }
    },
  },
};
</script>

<style></style>
