import Cookies from "js-cookie";
import { toast } from "@/controllers/alert";
const authenticated = async (props, data) => {
  try {
    await TokenHandle(props, data);
    await fetchUser(props);
  } catch (error) {
    console.log(error);
  }
};
const setNewUser = async (props, token) => {
  try {
    Cookies.set("token", token);
    Cookies.set("isLoggedIn", true);
    await props.$store.commit("auth/SET_TOKEN", token);
    props.$http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await setUser(props);
  } catch (error) {
    console.log(error);
  }
};

const fetchUser = async (props) => {
  try {
    const { data } = await props.$http.get("me");
    if (data.status) {
      await props.$store.commit("auth/SET_USER", data.data);
      localStorage.setItem("user", JSON.stringify(data.data));
      if (data.data.role === 2) {
        localStorage.setItem("currency", data.data.stores.currency);
        if (data.data.branch_id) {
          await props.$router.push("/sale");
        } else {
          await props.$router.push("/open-branch");
        }
      } else {
        await props.$router.push("/administrator/index");
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const setUser = async (props) => {
  try {
    const { data } = await props.$http.get("me");
    if (data.status) {
      await props.$store.commit("auth/SET_USER", data.data);
      localStorage.setItem("user", JSON.stringify(data.data));
    }
  } catch (error) {
    console.log("error", error);
  }
};

const checkToken = async (props) => {
  // check token expiration by comparing the current time and the expiration time in local storage
  const expired_at = localStorage.getItem("expired_at");
  const now = new Date();
  const expired = new Date(expired_at);
  const diff = (expired.getTime() - now.getTime()) / 1000 / 60;
  // check if token near to expiration in 2 minutes
  const reNewToken = process.env.VUE_APP_RENEW_TOKEN || 2;
  const min = parseInt(reNewToken);
  // convert to number
  if (diff < min) {
    // block the request until the token is refreshed and then release it with the new token
    await refreshToken(props);
  }
};

const refreshToken = async (props) => {
  try {
    const { data } = await props.$http.post("refresh");
    if (data.status) {
      const msg = "Renew token successfully";
      toast(props, msg, "info");
      await setUser(props);
      await TokenHandle(props, data);
      window.location.reload();
    }
  } catch (error) {
    console.log("error", error);
  }
};

const TokenHandle = async (props, data) => {
  try {
    const token = data.access_token;
    const expired_at = data.expires_in;
    localStorage.setItem("expired_at", expired_at);
    Cookies.set("token", token);
    Cookies.set("isLoggedIn", true);
    await props.$store.commit("auth/SET_TOKEN", token);
    props.$http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log("error", error);
  }
};

export { authenticated, fetchUser, setUser, setNewUser, checkToken };
