<template>
  <v-app dark>
    <app-loading :overlay="overlay" />
    <app-drawer-left ref="drawer" />
    <v-app-bar flat height="65" fixed app>
      <v-btn
        small
        fab
        elevation="0"
        @click.stop="$refs.drawer.drawer = !$refs.drawer.drawer"
      >
        <v-icon dark small>fal fa-stream</v-icon>
      </v-btn>
      <v-toolbar-title
        v-if="$vuetify.breakpoint.mdAndUp && store_type === 'POS'"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ posName }}</v-list-item-title>

            <v-list-item-subtitle>
              <span v-if="eye"
                >{{ moneyFormat(mySale.total) }} ({{ mySale.count }})</span
              >

              <v-chip label small v-else @click="setHide()">{{
                $t("sale_display")
              }}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip bottom v-if="eye">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  depressed
                  small
                  @click="setHide()"
                >
                  <v-icon small color="grey lighten-1">fal fa-eye-slash</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("sale_display") }}</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-toolbar-title>

      <v-spacer />
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            @click="getOrders()"
            v-bind="attrs"
            class="mr-4"
            fab
            depressed
            small
            v-on="{ ...menu }"
          >
            <v-icon small color="grey">fal fa-shopping-cart</v-icon>
          </v-btn>
        </template>
        <v-list v-if="orders.length">
          <template v-for="(val, index) in orders">
            <v-list-item :key="val.txn">
              <v-list-item-avatar class="pointer-cursor">
                <v-avatar color="info">
                  {{ val.id }}
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content
                @click="cartDetail(val.txn)"
                class="pointer-cursor"
              >
                <v-list-item-title>{{ val.txn }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t("quantity") }} :
                  {{ val.orders_count }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn fab depressed x-small @click="removeCart(val.txn)">
                  <v-icon x-small>fal fa-trash-alt</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < orders.length - 1"
              :key="index"
              inset
              class="my-0"
            ></v-divider>
          </template>
        </v-list>
        <v-card v-else>
          <v-card-text class="text-center">
            {{ $t("no_order") }}
          </v-card-text>
        </v-card>
      </v-menu>
      <v-row>
        <v-col sm="6" md="4" xl="4" lg="4">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="categories"
            :text="$i18n.locale === 'en' ? 'en_name' : 'la_name'"
            itemValue="id"
            v-model="product"
          />
        </v-col>
        <v-col sm="12" md="8" xl="8" lg="8" v-if="$vuetify.breakpoint.mdAndUp"
          ><v-text-field
            outlined
            flat
            autofocus
            class="search-product"
            hide-details
            v-model="search"
            color="primary"
            :placeholder="$t('search_by_code')"
            dense
            v-on:keyup.enter="searchProducts(search)"
            clearable
          >
            <template v-slot:append>
              <v-icon @click="searchProducts(search)" small color="grey"
                >fal fa-search</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click.stop="$refs.drawerRight.drawer = !$refs.drawerRight.drawer"
      />
    </v-app-bar>

    <v-main class="overflow-y-auto">
      <alertExpired />
      <router-view />
    </v-main>

    <app-drawer-right ref="drawerRight" />
  </v-app>
</template>

<script>
import alertExpired from "@/components/alertExpired.vue";
import AppDrawerLeft from "@/components/layouts/drawerLeft";
import AppDrawerRight from "@/components/layouts/drawerRight";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { toast, confirm } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";
import { setUser, checkToken } from "@/controllers/auth";
import TextSelect from "@/components/global/TextSelect";
import { mainStore } from "@/stores/modules/cart";

export default {
  components: { AppDrawerLeft, AppDrawerRight, TextSelect, alertExpired },
  mixins: [myMixin],
  data() {
    return {
      defineStore: mainStore(),
      eye: false,
      search: "",
      product: 0,
    };
  },
  watch: {
    product(val) {
      if (val === 0) {
        return this.searchProducts();
      }
      this.filterProduct(val);
      this.scrollToTop();
    },
  },

  created() {
    checkToken(this);
    const themes = localStorage.getItem("darkMode");
    if (themes) {
      if (themes.length === 4) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  mounted() {
    setUser(this);
    this.fetchCategory();
    if (this.store_type != "RESTAURANT") {
      // fetch table
      this.fetchMySale();
    }
    const hide = localStorage.getItem("hide");
    this.eye = hide === "true" ? true : false;
  },
  computed: {
    ...mapGetters({
      category: "index/category",
      user: "auth/user",
      mySale: "index/mySale",
      orders: "index/orders",
      overlay: "index/overlay",
    }),
    categories() {
      if (
        this.category != null &&
        this.category != undefined &&
        this.category.length
      ) {
        const object = {
          id: 0,
          la_name: "ທັງໝົດ",
          en_name: "All",
        };
        this.category.unshift(object);
        return this.category;
      } else {
        return [];
      }
    },
    posName() {
      if (
        this.user != null &&
        this.user != undefined &&
        this.user.stores != null &&
        this.user.stores != undefined &&
        this.user.stores.store_id != null &&
        this.user.stores.store_id != undefined &&
        this.user.stores.store_id.name != null &&
        this.user.stores.store_id.name
      ) {
        return this.user.stores.store_id.name;
      }
    },
  },
  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    ...mapMutations({
      SET_OVERLAY: "index/SET_OVERLAY",
    }),

    async fetchCategory() {
      const body = {
        url: "categories",
        that: this,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },
    async filterProduct({ id }) {
      this.SET_OVERLAY(true);
      const body = {
        that: this,
        url: `products_sale?cat_id=${id}`,
        commit: "SET_PRODUCT",
      };
      await this.fetch(body);
      this.SET_OVERLAY(false);
    },
    async searchProducts(val) {
      if (val) {
        this.SET_OVERLAY(true);
        const { data } = await this.$http.get(`get_cart?q=${val}`);
        this.SET_OVERLAY(false);
        if (data.status && data.data != null && data.data != undefined) {
          this.search = "";
          const body = {
            that: this,
            payload: data.data,
          };
          this.defineStore.addToCart(body);
          await toast(this, "add_to_cart_success");
        } else {
          await toast(this, "no_data", "error");
        }
      } else {
        this.SET_OVERLAY(true);

        const body = {
          that: this,
          url: "products_sale",
          commit: "SET_PRODUCT",
        };
        await this.fetch(body);
        this.SET_OVERLAY(false);
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0 });
    },
    async setHide() {
      this.eye = !this.eye;
      localStorage.setItem("hide", this.eye);
    },
    async cartDetail(txn) {
      this.SET_OVERLAY(true);
      const { data } = await this.$http.get(`get_order/${txn}`);
      if (data.status) {
        this.SET_OVERLAY(false);
        const cart = data.data.orders.map((e) => ({
          txn: txn,
          id: e.product_id,
          qty: e.qty,
          discount: 0,
          price: e.product.price_sale * e.qty,
          item: e.product,
        }));
        this.defineStore.setCart(cart);
      }
    },
    async getOrders() {
      try {
        this.SET_OVERLAY(true);
        const body = {
          url: "list_order",
          that: this,
          commit: "SET_ORDER",
        };
        setTimeout(() => {
          this.fetch(body);
          this.SET_OVERLAY(false);
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    async removeCart(txn) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_remove",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`delete_order/${txn}`);
        if (data.status) {
          this.defineStore.removeTxnCart(txn);
          await toast(this, data.message);
        }
      }
    },
  },
};
</script>
