<template>
  <v-card class="rounded-lg">
    <v-card-title>
      <v-icon left small> fal fa-store </v-icon
      >{{ $t("store_information") }}</v-card-title
    >
    <v-card-text class="pa-10 pt-0">
      <v-row>
        <v-col cols="6">
          <p>
            {{ $t("bank_qr") }}
          </p>
          <v-avatar color="#f5f5f5" size="158" tile class="rounded-lg">
            <v-avatar-uploader
              :url="urlQR"
              :tile="true"
              :clickable="true"
              field="file"
              :rename="rename"
              @failed="failed"
              :request="requestQR"
            />
          </v-avatar>
          <v-btn
            v-if="is_qr"
            @click="conRemove()"
            fab
            small
            class="ml-2"
            depressed
          >
            <v-icon small color="error">fal fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <TTextField
            :disabled="true"
            label="store_name"
            v-model="store.name"
          />
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="6" xl="6">
          <TTextField label="store_phone" v-model="store.phone_no" />
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="6" xl="6">
          <TTextField label="mobile_no" v-model="store.mobile_no"
        /></v-col>
        <v-col cols="12" class="py-0">
          <TTextField label="store_address" v-model="store.address" />
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="6" xl="6">
          <VuetifyMoney label="thb" v-model="store.thb" />
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="6" xl="6">
          <VuetifyMoney label="usd" v-model="store.usd" />
        </v-col>
        <v-col xs="12" sm="12" md="6">
          <VuetifyMoney
            v-model="store.price_per_point"
            label="price_per_point"
            :options="options"
          />
        </v-col>

        <v-col xs="12" sm="12" md="6">
          <TextSelect
            label="currency"
            v-model="store.currency"
            :items="currencies"
          />
        </v-col>
        <template v-if="store_type === 'POS'">
          <v-col xs="12" sm="12" md="12">
            <div class="pt-2 pb-1">
              {{ $t("refund_order") }}
            </div>
            <v-chip-group
              v-model="store.return_day"
              mandatory
              column
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip
                v-for="(day, i) in returnDays"
                :key="i"
                :value="day.value"
                filter
                outlined
              >
                {{ day.text + $t("day") }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </template>
        <v-col xs="12" sm="12" md="6">
          <v-switch
            class="mt-0"
            v-model="store.tax_include"
            inset
            hide-details="auto"
            :label="`${$t('tax_include')} ${tax}`"
          />
        </v-col>
        <!-- <v-col xs="12" sm="12" md="6" v-if="store.tax_include">
          <TTextField label="tax" v-model="store.tax" />
        </v-col> -->
      </v-row>
      <template v-if="store.store_type_code != 'POS'">
        <div class="pb-0 pt-3">
          {{ $t("smart_order") }}
        </div>
        <v-switch
          class="mt-0"
          v-model="store.online_order"
          inset
          hide-details="auto"
          :label="`
        ${$t('online_order')} ${
            store.online_order ? $t('open') : $t('close')
          } `"
        ></v-switch>
      </template>

      <TextArea label="description" v-model="store.desc" />
    </v-card-text>
    <v-card-actions class="px-10 pb-10">
      <v-btn
        @click="updateStore()"
        depressed
        color="primary"
        large
        class="px-12"
      >
        {{ $t("update") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TTextField from "@/components/global/TextField";
import TextArea from "@/components/global/TextArea";
import { mapGetters } from "vuex";
import VAvatarUploader from "@/components/VAvatarUploader";
import { setUser } from "@/controllers/auth";
import { success, confirm } from "@/controllers/alert";
import VuetifyMoney from "@/components/VuetifyMoney";
import { myMixin } from "@/mixin/main";
import TextSelect from "@/components/global/TextSelect";

export default {
  mixins: [myMixin],
  components: {
    TTextField,
    TextArea,
    VAvatarUploader,
    VuetifyMoney,
    TextSelect,
  },
  data() {
    return {
      store: {
        price_per_point: 0,
        online_order: 0,
      },
      options: {
        precision: 0,
      },
      currencies: ["LAK", "THB", "USD"],
      returnDays: [
        {
          text: "1",
          value: 1,
        },
        {
          text: "7",
          value: 7,
        },
        {
          text: "14",
          value: 14,
        },
        {
          text: "30",
          value: 30,
        },
      ],
    };
  },
  mounted() {
    this.store = this.user.stores;
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    urlQR() {
      if (this.user && this.user.stores && this.user.stores.qr) {
        // check if contains 'com'
        if (this.user.stores.qr.includes("com")) {
          return this.user.stores.qr;
        }
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.user.stores.qr}`;
      } else {
        return "/images/qrcode.png";
      }
    },
    is_qr() {
      return this.user.stores.qr ? true : false;
    },
  },
  methods: {
    rename(file) {
      const ext = file.name.split(".")[1];
      const name = `1231232.${ext}`;
      return name;
    },
    failed(error) {
      console.log("error", error);
    },
    async conRemove() {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "qr_removed",
          can_txt: "cancel",
          con_txt: "confirm",
        });
        if (isConfirmed) {
          await this.removeQR();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async removeQR() {
      try {
        const { data } = await this.$http.post("remove_qr");
        if (data.status) {
          await setUser(this);
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async requestQR(form, config) {
      const { data } = await this.$http.post("change_store_qr", form, config);
      if (data.status) {
        await setUser(this);
        await success(this, data.message);
      }
    },
    async updateStore() {
      try {
        const form = {
          mobile_no: this.store.mobile_no,
          address: this.store.address,
          phone_no: this.store.phone_no,
          desc: this.store.desc,
          thb: this.store.thb,
          usd: this.store.usd,
          return_day: this.store.return_day,
          price_per_point: this.store.price_per_point,
          online_order: this.store.online_order,
          currency: this.store.currency,
          tax_include: this.store.tax_include,
        };
        const { data } = await this.$http.post("store_update", form);
        if (data.status) {
          localStorage.setItem("currency", this.store.currency);
          await setUser(this);
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
