import moment from "moment";
import { mapGetters } from "vuex";

const myMixin = {
  filters: {
    moment(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    DatePrint(date) {
      //  21, Jan 2021
      return moment(date).format("DD, MMM YYYY");
    },
    created_at(date) {
      // if date is null or undefined return 0
      if (date === null || date === undefined) return "";
      return moment(date).format("DD-MM-YYYY");
    },

    NumberFormat(val) {
      // format number with commas and .00 format
      if (val === null || val === undefined) return "0.00";
      // if string convert to number
      if (typeof val === "string") {
        val = parseFloat(val);
      }
      return val
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    ToNumber(val) {
      // val must not null or undefined
      if (val === null || val === undefined) return "0";

      //  format number  with commas
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      taxInclude: "auth/tax_include",
      tax: "auth/tax",
      tax_rate: "auth/tax_rate",
      store_type: "auth/store_type",
    }),

    cartItemQty() {
      const cart = this.defineStore.cart;
      // 4 items | 10 products
      // ToNumber
      const item = this.$options.filters.ToNumber(cart.length);
      const product = cart.reduce((acc, item) => acc + item.qty, 0);
      const products_format = this.$options.filters.ToNumber(product);
      const itemText = item > 1 ? "items" : "item";
      return `${item} ${this.$t(itemText)} | ${products_format} ${this.$t(
        "quantity"
      )}`;
    },
    currency() {
      // 'USD', 'THB', 'LAK'
      switch (this.user.stores.currency) {
        case "USD":
          return "$";
        case "THB":
          return "฿";
        default:
          return "₭";
      }
    },
  },
  methods: {
    moneyFormat(val) {
      if (val != null && val != undefined && val != "" && val != 0) {
        return Help.moneyFormat(val);
      } else {
        return "0";
      }
    },
    moneyFormatPrint(item) {
      if (item != null && item != undefined) {
        return Help.moneyFormatPrint(item);
      }
    },
    IMAGES(val) {
      return Help.img(val);
    },

    CompareArray(array1, array2) {
      var result = [];
      array1.map((item1, index) => {
        array2.map((item2, index) => {
          if (item1.id === item2.id && item1.stock != item2.stock) {
            if (
              item1.price_buy === item2.price_buy &&
              item1.price_sale === item2.price_sale
            ) {
              // same product id
              // same price buy
              // same price sale
              // just update the stock price
              const data = {
                id: item2.id,
                vendor_id: Help.getId(item2.vendor_id),
                stock: item2.stock,
              };
              data.status = false;
              result.push(data);
            } else if (
              item1.price_buy != item2.price_buy ||
              item1.price_sale != item2.price_sale
            ) {
              // same product id
              // create the new product
              // cp image name change file name to current timestamp
              // get the image name to insert to data base
              var d1 = new Date();
              const img = item2.img.split(".");
              const img_path = img[0].split("/");
              const img_name = `${img_path[0]}/${Math.floor(
                d1.getTime() / 1000
              )}.${img[1]}`;
              var data = {
                id: item2.id,
                vendor_id: Help.getId(item2.vendor_id),
                stock: item2.stock,
                price_buy: item2.price_buy,
                price_sale: item2.price_sale,
                img: item2.img,
                img_name: img_name,
              };
              data.status = true;
              result.push(data);
            }
          }
        });
      });
      return result;
    },

    userRole(val) {
      switch (val) {
        case 1:
          return this.$t("admin");
        case 2:
          return this.$t("owner_store");
        case 3:
          return this.$t("manager");
        case 4:
          return this.$t("assistant");
        case 5:
          return this.$t("staff");
        default:
          return this.$t("unknown_user");
      }
    },
    RoleColor(val) {
      // '1:admin;2:owner;3:manger;4:assistant;5:staff;6:customer'
      switch (val) {
        case 1:
          return "red accent-2";
        case 2:
          return "green";
        case 3:
          return "orange accent-2";
        case 4:
          return "blue accent-2";
        case 5:
          return "blue-grey darken-1";
        default:
          return "blue-grey darken-1";
      }
    },
    MyRole(code) {
      const { auth_menus } = this.user;
      // loop menu and check if the code is in the menu code, check in sub menu if the code is in the sub menu code
      // if the code is in the menu code or sub menu code return true
      // if not return false
      var result = false;
      // check auth_menus is not null or undefined
      if (!auth_menus || auth_menus == null || !auth_menus.length) {
        return result;
      }
      // loop auth_menus
      auth_menus.map((item) => {
        if (item.code === code) {
          result = true;
        }
        if (item.menu && item.menu.length) {
          item.menu.map((sub) => {
            if (sub.code === code) {
              result = true;
            }
          });
        }
      });
      // return result;
      return true;
    },
    statusUserStore(item) {
      if (item.stores) {
        if (item.stores.store_id.status) {
          return {
            color: "green",
            status: true,
            title: "account_active",
          };
        } else {
          return {
            color: "red",
            status: false,
            title: "account_inactive",
          };
        }
      } else {
        return {
          color: "blue-grey",
          status: true,
          title: "no_store",
        };
      }
    },
    async fetchMySale() {
      try {
        const body = {
          url: "my_sale",
          that: this,
          commit: "SET_MY_SALE",
        };
        await this.fetch(body);
      } catch (error) {
        console.log(error);
      }
    },

    getDay(stores) {
      const start_date = stores.store_id.start_date;
      const end_date = stores.store_id.end_date;
      // calculate the difference between two dates
      const differenceInTime = Date.parse(end_date) - Date.parse(start_date);
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );
      return differenceInDays;
    },
    getEndDay(stores) {
      const end_date = stores.store_id.end_date;
      // how many day between today's date with the end date
      const differenceInTime = Date.parse(end_date) - Date.parse(new Date());
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );
      return differenceInDays;
    },
    userPackage(stores) {
      if (
        stores &&
        stores.store_id &&
        stores.store_id.package &&
        stores.store_id.package.name
      ) {
        const packageName = stores.store_id.package.name;
        const packageDetail = stores.store_id.package;
        switch (packageName) {
          case "free":
            return {
              color: "blue",
              text: packageName,
              package: packageDetail,
            };
          case "basic":
            return {
              color: "#5b39c91f",
              text: packageName,
              icon: "fal fa-layer-group",
              package: packageDetail,
            };
          case "pro":
            return {
              color: "#D2AF26 ",
              text: packageName,
              package: packageDetail,
            };
          default:
            return {
              color: "primary",
              text: packageName,
              package: packageDetail,
            };
        }
      } else {
        return {
          color: "red",
          text: "no_store",
        };
      }
    },
    packageName(name) {
      switch (name) {
        case "free":
          return {
            color: "warning",
            text: name,
          };
        case "basic":
          return {
            color: "blue",
            text: name,
          };
        case "pro":
          return {
            color: "primary",
            text: name,
          };
        default:
          return {
            color: "primary",
            text: name,
          };
      }
    },
    formatNumber(val) {
      //  format number  with commas
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    branchStatus(val) {
      if (this.user.branch_id && val.id === this.user.branch_id.id) {
        return {
          color: "green",
          icon: "fal fa-check-circle",
          text: "current",
        };
      }
      return {
        color: "red",
        icon: "fal fa-times-circle",
        text: "move",
      };
    },
    orderStatusColor(val) {
      switch (val) {
        case "pending":
          return "orange";
        case "processing":
          return "blue";
        case "completed":
          return "green";
        case "done":
          return "green";
        case "decline":
          return "red";
        case "cancel":
          return "red";
        case "approved":
          return "green";
        case "rejected":
          return "red";
        case "order":
          return "orange";
        case "cooking":
          return "blue";
        case "ready":
          return "success";
        case "cancel":
          return "error";
        case "delivered":
          return "primary";
        case "paid":
          return "green";
        case "accepted":
          return "green";
        default:
          return "orange";
      }
    },

    userActiveOption(val) {
      if (val.is_active) {
        return {
          color: "red",
          icon: "fal fa-lock",
          text: "active",
        };
      }
      return {
        color: "green",
        icon: "fal fa-unlock",
        text: "inactive",
      };
    },
    img(item) {
      const _default = "/images/no_photo.jpg";
      try {
        if (item != null && item != undefined && item.img != null) {
          // check if img is contain 'com'
          if (item.img.includes("com")) {
            return item.img;
          } else if (item.img.includes("default")) {
            return _default;
          } else {
            const baseURL = process.env.VUE_APP_BASE_URL;
            return `${baseURL}/uploads/${item.img}`;
          }
        } else {
          return _default;
        }
      } catch (error) {
        return _default;
      }
    },

    pointFormat(item) {
      if (item != null && item != undefined) {
        return Help.pointFormat(item);
      } else {
        return 0;
      }
    },
    price_show(index, value, show_restaurant) {
      const res = 400000;
      if (index === 2) {
        return this.$t("customize");
      } else if (value === 0) {
        return this.$options.filters.NumberFormat(value);
      }
      if (show_restaurant) {
        return this.$options.filters.NumberFormat(res) + " " + this.$t("month");
      } else {
        return (
          this.$options.filters.NumberFormat(value) + " " + this.$t("month")
        );
      }
    },

    product_count(value) {
      if (
        value === 0 ||
        value === null ||
        value === undefined ||
        value === "0"
      ) {
        return this.$t("customize");
      } else {
        return value + " " + this.$t("item");
      }
    },
    bill_count(value) {
      if (value === 0) {
        return this.$t("unlimited");
      } else {
        return value + " " + this.$t("bill");
      }
    },
    user_count(value) {
      if (value === 0) {
        return this.$t("unlimited");
      } else {
        return value + " " + this.$t("user");
      }
    },
    branch_count(value) {
      if (value === 0) {
        return this.$t("unlimited");
      } else {
        return value + " " + this.$t("branch");
      }
    },
    tableStatusColor(val) {
      // available, busy, reserved
      switch (val) {
        case "available":
          return "green";
        case "busy":
          return "orange";
        case "reserved":
          return "red";
        default:
          return "green";
      }
    },
    iconStatus(val) {
      // available, busy, reserved
      switch (val) {
        case "available":
          return "fas fa-check-circle";
        case "busy":
          return "fas fa-times-circle";
        case "reserved":
          return "fas fa-lock";
        default:
          return "fas fa-check-circle";
      }
    },

    StringToQRCode(val) {
      // get host url from path
      const baseURL = window.location.origin ?? "https://pos.devla.la";
      const path = `${baseURL}/scan/${val}`;
      // convert string to qr code
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${path}`;
    },

    TaxCal(val) {
      if (val === null || val === undefined) return 0;
      if (this.taxInclude && this.tax > 0) {
        const amt = val * (this.tax / 100);
        // called filter to format number
        return amt;
      }
      return val;
    },
    TaxPrice(val) {
      if (val === null || val === undefined) return 0;
      if (this.taxInclude && this.tax > 0) {
        const amt = val + val * (this.tax / 100);
        return amt;
      }
      return val;
    },

    copyToClipboard(val) {
      // copy to clipboard
      navigator.clipboard.writeText(val);
      const msg = this.$t("copied");
      this.$toast.success(msg);
    },
    openURL(img) {
      // check if img is contain 'com'
      if (img.includes("com")) {
        window.open(img, "_blank").focus();
        return;
      }
      const url = Help.product(img);
      window.open(url, "_blank").focus();
      return;
    },
    async sendWhatsapp(store) {
      try {
        const { mobile_no, name, end_date } = store;
        const endFormat = this.$options.filters.created_at(end_date);
        const msg =
          `ສະບາຍດີຮ້ານ *${name}* ທີ່ຮັກແພງ! 🙏%0A` +
          `📢 ພວກເຮົາຂໍແຈ້ງມາຍັງທ່ານວ່າ ບັນຊີຂອງຮ້ານຂອງທ່ານຈະໝົດກຳນົດການນຳໃຊ້ໃນວັນທີ *${endFormat}*.✨%0A` +
          `ເພື່ອໃຫ້ທ່ານສາມາດນຳໃຊ້ບໍລິການໄດ້ຢ່າງຕໍ່ເນື່ອງ, ກະລຸນາຕໍ່ອາຍຸບັນຊີໂດຍການສະແກນ QR Code ດ້ານລຸ່ມນີ້. 📱%0A` +
          `🙏 ຂອບໃຈທີ່ໄວ້ວາງໃຈໃຊ້ບໍລິການຂອງພວກເຮົາມາໂດຍຕະຫຼອດ. ຫາກມີຂໍ້ສົງໄສກະລຸນາຕິດຕໍ່ພວກເຮົາໄດ້ທຸກເວລາ. 💬%0A` +
          `🔹 *ຂັ້ນຕອນການຕໍ່ອາຍຸບັນຊີຂອງຮ້ານ* 🔹%0A` +
          `1️⃣ ເຂົ້າໄປທີ່ *ຕັ້ງຄ່າ* -> *ທົ່ວໄປ*%0A` +
          `2️⃣ ເລືອກ *ເພັກເກັດ*%0A` +
          `3️⃣ ຕິກໃສເມນູ *ສິຂ່ຽວ*%0A` +
          `4️⃣ *ສະແກນ QR Code* ເພື່ອຊຳລະ%0A` +
          `💖 ຂອບໃຈທີ່ໃຊ້ບໍລິການ PosSmart!`;
        const url = `https://api.whatsapp.com/send?phone=${mobile_no}&text=${msg}`;
        window.open(url, "_blank").focus();
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export { myMixin };
