<template>
  <v-container fluid v-if="daysLeft <= 5">
    <v-alert
      color="red"
      border="left"
      dark
      text
      prominent
      type="error"
      icon="fal fa-exclamation-triangle"
    >
      <v-row>
        <v-col cols="10">
          {{ msg }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn depressed outlined color="red" @click="to()">
            <v-icon small left>fal fa-cog</v-icon>
            <span>{{ $t("settings") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "AlertExpired",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    daysLeft() {
      const { end_date } = this.user.stores;
      const today = new Date();
      const expiry = new Date(end_date);
      const diffTime = expiry - today;
      return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0);
    },
    msg() {
      // get current language
      const lang = this.$i18n.locale;
      const { end_date } = this.user.stores;
      const date = moment(end_date).format("DD-MM-YYYY");
      if (lang === "la") {
        const _msg = `ແພັກເກັດຮ້ານຂອງທ່ານຈະໝົດອາຍຸໃນວັນທີ [${date}]  ກະລຸນາຕໍອາຍຸເພື່ອບໃຫ້ການບໍລິການຢ່າງຕໍເນື່ອງ.`;
        return _msg;
      } else {
        const _mgs = `Your store subscription will expire on [${date}] please renew it to continue your business.`;
        return _mgs;
      }
    },
    to() {
      localStorage.setItem("selectedItem", 4);
      this.$router.push("/settings-general");
    },
  },
};
</script>
