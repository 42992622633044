<template>
  <div class="row mt-0">
    <div class="col-ms-12 col-md-7 col-lg-7 col-xl-7">
      <v-card class="rounded-lg pb-5">
        <v-form>
          <v-card-title class="pb-0">{{ $t("new_product") }}</v-card-title>
          <v-card-text
            :class="$vuetify.breakpoint.mobile ? '' : 'px-10 pt-5 pb-0'"
          >
            <TTextField
              label="barcode"
              :counter="true"
              :errorMessages="codeError"
              v-model="form.code"
              @input="$v.form.code.$touch()"
              @blur="$v.form.code.$touch()"
            />
            <FileInput label="image" v-model="imgFile" :multiple="true" />

            <TTextField
              label="product_name"
              v-model="form.name"
              :errorMessages="nameError"
              @input="$v.form.name.$touch()"
              @blur="$v.form.name.$touch()"
            />
            <v-row>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <TTextField :disabled="true" label="stock" v-model="form.stock"
              /></v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <VuetifyMoney
                  typeShow="number"
                  :options="options"
                  :errorMessages="notify_stockError"
                  label="stock_notify"
                  v-model="form.notify_stock"
                  @input="$v.form.notify_stock.$touch()"
                  @blur="$v.form.notify_stock.$touch()"
              /></v-col>
            </v-row>
            <v-row class="my-0">
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <VuetifyMoney
                  v-model="form.price_buy"
                  @input="$v.form.price_buy.$touch()"
                  @blur="$v.form.price_buy.$touch()"
                  :errorMessages="price_buyError"
                  label="price_buy"
                />
              </v-col>
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <VuetifyMoney
                  :errorMessages="price_saleError"
                  v-model="form.price_sale"
                  @input="$v.form.price_sale.$touch()"
                  @blur="$v.form.price_sale.$touch()"
                  label="price_sale"
              /></v-col>
            </v-row>

            <TextSelect
              label="category"
              :errorMessages="category_idError"
              v-model="form.category_id"
              @input="$v.form.category_id.$touch()"
              @blur="$v.form.category_id.$touch()"
              itemValue="id"
              text="la_name"
              :items="categories"
            />

            <TextSelect
              label="unit"
              :errorMessages="size_idError"
              v-model="form.size_id"
              @input="$v.form.size_id.$touch()"
              @blur="$v.form.size_id.$touch()"
              itemValue="id"
              text="name"
              :items="sizes"
            />
            <TextSelect
              label="supplier"
              v-model="form.vendor_id"
              @input="$v.form.vendor_id.$touch()"
              @blur="$v.form.vendor_id.$touch()"
              :errorMessages="vendor_idError"
              itemValue="id"
              text="name"
              :items="suppliers"
            />
            <v-checkbox
              class="expired-check"
              v-model="expiredPro"
              hide-details
              :label="$t('expired_product')"
            ></v-checkbox>
            <template v-if="expiredPro">
              <DatePicker label="expired_date" v-model="form.expired_date" />
            </template>
            <TextArea label="description" v-model="form.desc" />
          </v-card-text>
          <v-card-actions class="my-4">
            <v-spacer />
            <v-btn
              @click="clearForm()"
              large
              dark
              class="px-8"
              color="#ff0000"
              depressed
            >
              <v-icon left> fal fa-times</v-icon> {{ $t("clear_form") }}
            </v-btn>
            <v-btn
              @click="submitForm(form)"
              color="primary"
              large
              dark
              class="px-8"
              depressed
            >
              <v-icon left> fal fa-upload</v-icon>{{ $t("save") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
    <app-preview
      v-if="!$vuetify.breakpoint.mobile"
      :form="form"
      :imgFile="imgFile"
      :isEdit="false"
    />
    <app-loading :overlay="overlay" />
  </div>
</template>

<script>
import TTextField from "@/components/global/TextField";
import TextSelect from "@/components/global/TextSelect";
import FileInput from "@/components/global/FileInput";
import VuetifyMoney from "@/components/VuetifyMoney";
import TextArea from "@/components/global/TextArea";
import { mapGetters, mapActions } from "vuex";
import AppPreview from "@/components/products/preview";
import { addProduct } from "@/validations/shop";
import { success, confirm } from "@/controllers/alert";
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);
export default {
  mixins: [addProduct],
  data: () => ({
    imgFile: null,
    expiredPro: false,
    overlay: false,
    options: {
      precision: 0,
    },
    form: {
      code: "",
      name: "",
      desc: "",
      stock: 0,
      expired_date: today,
      notify_stock: 10,
      price_sale: 0,
      price_buy: 0,
      category_id: null,
      vendor_id: null,
      size_id: null,
    },
  }),
  components: {
    FileInput,
    TextSelect,
    TTextField,
    TextArea,
    VuetifyMoney,
    AppPreview,
  },
  computed: {
    ...mapGetters({
      category: "index/category",
      suppliersData: "index/suppliers",
      sizes: "index/sizes",
    }),
    categories() {
      if (
        this.category != null &&
        this.category != undefined &&
        this.category.length
      ) {
        return this.category;
      } else {
        return [];
      }
    },
    suppliers() {
      if (
        this.suppliersData != null &&
        this.suppliersData != undefined &&
        this.suppliersData.length
      ) {
        return this.suppliersData;
      } else {
        return [];
      }
    },
  },
  created() {
    this.fetchSize();
    this.fetchVendor();
    this.fetchCategory();
  },

  watch: {
    expiredPro(val) {
      if (val) {
        const expired_date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.form.expired_date = expired_date;
      } else {
        this.form.expired_date = null;
      }
    },
  },
  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchVendor() {
      const body = {
        that: this,
        url: "suppliers",
        commit: "SET_SUPPLIERS",
      };
      await this.fetch(body);
    },
    async fetchSize() {
      const body = {
        that: this,
        url: "product_size",
        commit: "SET_SIZES",
      };
      await this.fetch(body);
    },
    async fetchCategory() {
      const body = {
        that: this,
        url: "categories",
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },
    async submitForm(form) {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          this.overlay = true;
          let formData = new FormData();
          formData.append("code", form.code);
          formData.append("name", form.name);
          // check if imgFile is more then one file then loop through and append to formData
          if (Array.isArray(this.imgFile) && this.imgFile.length > 0) {
            for (let i = 0; i < this.imgFile.length; i++) {
              formData.append("file[]", this.imgFile[i]);
            }
          } else {
            formData.append("file", this.imgFile);
          }
          formData.append("desc", form.desc);
          if (this.expiredPro) {
            formData.append("expired_date", form.expired_date);
          }
          formData.append("notify_stock", form.notify_stock);
          formData.append("price_sale", form.price_sale);
          formData.append("price_buy", form.price_buy);
          formData.append("vendor_id", form.vendor_id.id);
          formData.append("category_id", form.category_id.id);
          formData.append("size_id", form.size_id.id);
          const { data } = await this.$http.post(
            "add_products",
            formData,
            Help.headerFile()
          );
          this.overlay = false;
          if (data.status) {
            this.clearData();
            await success(this, data.message);
          }
        }
        return;
      } catch (err) {
        this.overlay = false;
        console.log(err);
      }
    },
    async clearForm() {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_clear_form",
      });

      if (isConfirmed) {
        this.clearData();
      }
    },
    clearData() {
      this.form = {
        code: null,
        name: null,
        desc: null,
        stock: 0,
        expired_date: today,
        notify_stock: null,
        price_sale: 0,
        price_buy: 0,
        category_id: null,
        vendor_id: null,
        size_id: null,
      };
      this.imgFile = null;
      this.expiredPro = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>

<style></style>
